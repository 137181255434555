<template>
    <div>
        <div class="row" v-if="documentFolders.length">
            <div class="col-12">
                <p class="main-text">Project Folders</p>
            </div>
            <div class="col-12 col-md-6 col-lg-3" v-for="(f,index) in documentFolders" :key="index">
                <folder-card :name="f.name" :total="f.noOfDocuments" :owner="`${f.createdBy.first_name} ${f.createdBy.last_name}`" :collaborators="f.collaborators" @moveNext="$router.push({path:`/documents/${f.name}/${f._id}`})"/>
            </div>
            <div class="col-12 col-md-6 col-lg-3 p-2rem" v-if="getUserRole=='service_provider'">
                <create-folder-button class="h-100" name="Create Project" @onCreate="()=>$refs.createFolder.showModal()"/>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <app-paginated-table :fields="getFields" :items="items" :current-page="filters.page" :total-pages="total_pages" :total="total_records" :loading="loading" @onRowClicked="(e)=>viewDetails(documentType,e._id, e.status)" @onPageChange="onPageChange" :mobileHeaders="['document_name','actions']" @onLoadMore="loadMoreItems">
                    <template #title>
                        <p class="main-text mx-2 mt-2">Documents</p>
                    </template>
                    <template #header>
                        <b-tabs v-model="documentType" @input="getDocumnetsList(getDocType, filters)">
                            <b-tab title="Received" value="received" active v-if="getUserRole=='client'"></b-tab>
                            <b-tab title="Sent" value="sent" active v-else></b-tab>
                        </b-tabs>   
                    </template>
                    <template #filters>
                        <div class="row">
                            <div class="col-lg-4 col-md-6 col-12">
                                <app-text-input v-model="filters.search" placeholder="Search" @input="getDocumnetsList(getDocType, filters)"></app-text-input>
                            </div>
                            <div class="col-lg-4 col-md-6 col-12">
                                <app-select-box v-model="filters.status" :options="getStatusFilters" @onChange="getDocumnetsList(getDocType, filters)"></app-select-box>
                            </div>
                        </div>
                    </template>
                    <template #head(actions)>
                        {{ '' }}
                    </template>
                    <template #cell(document_name)="data">
                        <img :src="require('@/assets/images/app-icons/pdf.png')" alt="" class="mr-1">
                        {{ data.item.documentName }}
                    </template>
                    <template #cell(collaborator)="data">
                        <b-avatar-group size="32px">
                            <b-avatar
                            :class="[data.item.collaborators.length<2?'mx-0':'']" v-for="(c,index) in data.item.collaborators.slice(0,1)" :key="index"
                            :text="c.email.slice(0,1).toUpperCase()"
                            :variant="colors[index]"
                            v-b-tooltip.hover.v-primary="c.email"
                          />
                          <b-avatar v-if="data.item.collaborators.length > 1" 
                            class="mx-0" 
                            :text="`+${data.item.collaborators.length-1}`"
                            variant="light-success"
                            id="tooltip-avatar"
                            v-b-tooltip.hover.v-primary="getcollaborators(data.item.collaborators.slice(1))"
                            />
                        </b-avatar-group>
                    </template>
                    <template #cell(actions)="data">
                        <b-dropdown
                            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                            text="Block Level Dropdown Menu"
                            right
                            variant="none"
                            size="sm"
                            boundary="viewport"
                            no-caret
                            >
                            <template #button-content>
                                <feather-icon
                                    icon="MoreVerticalIcon"
                                    size="18"
                                />
                            </template>
                            <b-dropdown-item class="d-md-none" @click="viewDetails(documentType,data.item._id, data.item.status)">View Document</b-dropdown-item>
                            <b-dropdown-item v-if="data.item.status=='awaiting_signature'" @click="showPendingSignatures(data.item.pendingSignatures,data.item.signedBy)">Pending Signatures <b-badge class="ml-1 rounded-circle" variant="light-primary">{{data.item.pendingSignatures.length}}</b-badge></b-dropdown-item>
                            <!-- <b-dropdown-item @click="saveToBackup(data.item._id)">Save to Backups</b-dropdown-item> -->
                            <b-dropdown-item @click="getDocumnet(data.item._id)" :disabled="data.item.finalDocument && !data.item.isProjectFullyPaid">Download</b-dropdown-item>
                        </b-dropdown>            
                    </template>
                    <template #cell(sent_by)="data">
                        <b-avatar
                            class="pull-up"
                            :text="toCapitalize(data.item.sentBy[0].first_name.charAt(0))"
                            variant="light-primary"
                        />
                        {{ `${toCapitalize(data.item.sentBy[0].first_name)} ${toCapitalize(data.item.sentBy[0].last_name)}` }}
                    </template>
                    <template #cell(status)="data">
                        <b-badge :variant="data.item.status=='sent'? 'light-success':data.item.status=='completed'?'light-primary':data.item.status=='denied'?'light-danger':data.item.status=='awaiting_signature'?'light-warning':data.item.status=='reviewed'?'light-info':'light-secondary'">
                            {{ toCapitalize(data.item.status) }}
                        </b-badge>
                    </template>
                    <template #cell(totalDeposited)="data">
                        {{ data.item.totalDeposited | currency }}
                    </template>
                    <!-- <template #cell(projectName)="data">
                        {{ data.item.folder?data.item.folder.name:'N/A' }}
                    </template> -->
                    <template #cell(balanceDue)="data">
                        {{ data.item.balanceDue| currency }}
                    </template>
                    <template #cell(createdAt)="data">
                        {{ getFormatedDate(data.item.createdAt,"DD MMM YYYY") }}
                    </template>
                    <template #cell(updatedAt)="data">
                        {{ getFormatedDate(data.item.updatedAt,"DD MMM YYYY") }}
                    </template>
                </app-paginated-table>
            </div>
        </div>
        <create-folder ref="createFolder" @next="getFolders()"/>
        <pending-signatures ref="pendingsignature"/>
    </div>
</template>
<script>
import {BTabs,BTab, BDropdown, BDropdownItem, BBadge, BAvatarGroup, BAvatar, VBTooltip, BTooltip} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import FolderCard from '@/components/functional-components/other/documents/FolderCard.vue';
import AppHeading2 from '@/components/app-components/AppHeading2.vue';
import CreateFolderButton from '@/components/functional-components/other/documents/CreateFolderButton.vue';
import AppPaginatedTable from '@/components/app-components/AppPaginatedTable.vue';
import AppTextInput from '@/components/app-components/AppTextInput.vue';
import AppSelectBox from '@/components/app-components/AppSelectBox.vue';
import CreateFolder from '@/components/functional-components/other/documents/CreateFolder.vue';
import PendingSignatures from '@/components/functional-components/other/documents/PendingSignatures.vue'
import {get_folder_list, get_documents_list,save_to_backup, get_document} from '@/apis/others/documents'
import {getUserData} from '@/auth/utils'
import moment from 'moment'
import { eventBus } from '@/utils/eventbus'
export default {
    directives: {
        'b-tooltip': VBTooltip,
        Ripple
    },
    components: {
        BTabs,
        BTab, 
        BDropdown, 
        BDropdownItem, 
        BBadge, 
        BAvatarGroup, 
        BAvatar,
        FolderCard,
        AppHeading2,
        CreateFolderButton,
        AppPaginatedTable,
        AppTextInput,
        AppSelectBox,
        CreateFolder,
        PendingSignatures,
        BTooltip
    },
    data(){
        return{
            documentType:0,
            documentFolders:[],
            fields:[
                {
                    key:'document_name',
                    label:'Document Name'
                },
                {
                    key:'projectName',
                    label:'Project Name',
                    sortable:true
                },
                {
                    key:'collaborator',
                    label:'Collaborator'
                },
                {
                    key:'totalDeposited',
                    label:'Amount Deposited',
                    sortable:true
                },
                {
                    key:'balanceDue',
                    label:'Balance Due',
                    sortable:true
                },
                {
                    key:'status',
                    label:'Status',
                    sortable:true
                },
                {
                    key:'sent_by',
                    label:'Sent By'
                },
                {
                    key:'createdAt',
                    label:'Date Uploaded',
                    sortable:true
                },
                {
                    key:'updatedAt',
                    label:'Last Modified',
                    sortable:true
                },
                {
                    key:'actions',
                }
            ],
            items:[],
            filters:{
                limit:10,
                page:1,
                search:'',
                status:'',
                startDate:null,
                endDate:null,
            },
            options: [
                { value: '', text: 'Status' },
                { value: 'draft', text: 'Draft'},
                { value: 'awaiting_signature', text: 'Awaiting Signature' },
                { value: 'ready_to_view', text: 'Ready to View' },
                { value: 'reviewed', text: 'Reviewed' },
                { value: 'denied', text: 'Denied' },
                { value: 'completed', text: 'Completed' },
            ],
            colors:['light-primary','light-info','light-success','light-danger','light-secondary'],
            loading:false,
            current_page:1,
            total_records:0,
            total_pages:1,
            loadMore:false,
        }
    },
    computed:{
        getDocType(){
            let user=getUserData();
            return user.role=='client'?'received':'sent'
        },
        getStatusFilters(){
            return this.documentType==0?this.options.filter(o=>o.value!='draft'):this.options
        },
        getFields(){
            let user=getUserData();
            return user.role=='client'?this.fields.filter(f=>!['balanceDue','totalDeposited'].includes(f.key)):this.fields
        },
        getUserRole(){
            return getUserData().role
        }
    },
    created(){
        eventBus.$off('on-folder-created')
        eventBus.$on('on-folder-created',()=>{
            this.getFolders()
            // eventBus.$off('on-folder-created')
        })
    },
    watch:{
        documentType(){
            this.filters.search=''
            this.filters.status=''
        }
    },
    mounted(){
        this.getFolders()
        this.getDocumnetsList(this.getDocType, this.filters)
    },
    methods:{
        getFolders(){
            get_folder_list(this.getUserRole=='client'?'as-client':'as-service-provider').then(response=>{
                this.documentFolders = response.data
            })
        },
        viewDetails(type,id, status){
            if(this.getUserRole == 'client'){
                this.$router.push({path:`/documents/recieved`,query:{id:id}})
            }else{
                if(status =='draft'){
                    this.$router.push(`/document/details/${id}`)
                }else{
                    this.$router.push({path:`/documents/sent`,query:{id:id}})
                }
            }
        },
        getDocumnetsList(type,filters){
            this.loading=true
            get_documents_list(type,filters).then(resp=>{
                this.loading=false
                this.items = this.loadMore?[...this.items,...this.setDocumentsData(resp.data.documentsWithPendingSignatures)]:this.setDocumentsData(resp.data.documentsWithPendingSignatures)
                this.current_page=Number(resp.data.currentPage)
                this.total_records=resp.data.documentsCount
                this.total_pages=resp.data.documentsCount>0?resp.data.documentsCount/this.filters.limit:0
                this.loadMore=false
            })
        },
        setDocumentsData(documents){
            let newData=documents.map(d=>{
                return {
                    projectName:d.folder?d.folder.name:'N/A',
                    ...d
                }
            })
            return newData
        },
        getFormatedDate(date,format){
            return moment(date).format(format)
        },
        toCapitalize(name){
            console.log('name:',name)
            let words = name.split("_")
            let upperCaseStr= words.map(w=>`${w.charAt(0).toUpperCase()}${w.slice(1)}`).join(" ")
            return upperCaseStr
        },
        // setItems(items){
        //     let itemsList=[];
        //     items.forEach(item=>{
        //         let obj={
        //             ...item._doc,
        //             pendingSignatures:item.pendingSignatures
        //         }
        //         itemsList.push(obj)
        //     })
        //     this.items=itemsList
        // },
        showPendingSignatures(pending_signatures, signed_by){
           
            let pending_sign=pending_signatures.map(s=>{
                return {
                    id:s._id,
                    name:`${s.first_name} ${s.last_name}`,
                    email:s.email,
                    signed:false
                }
            })
            let signed=signed_by.map(s=>{
                return {
                    id:s._id,
                    name:`${s.first_name} ${s.last_name}`,
                    email:s.email,
                    signed:true
                }
            })
            this.$refs.pendingsignature.showModal([...signed, ...pending_sign])
        },
        saveToBackup(id){
            save_to_backup(id).then(resp=>{
                this.$bvToast.toast(resp.message, {
                        title: 'Saved to backup',
                        variant: 'success',
                        solid: true,
                    })
            }).catch(error => {
                this.$bvToast.toast(error.message, {
                        title: error.error,
                        variant: 'danger',
                        solid: true,
                    }) 
             })
        },
        onPageChange(page){
            this.filters.page=page;
            this.getDocumnetsList(this.getDocType, this.filters)
        },
        getDocumnet(id){
            get_document(id).then(resp=>{
               this.download(resp.data.fileLink, resp.data.document.document)
            })
        },
        download(url,name){
            var link = document.createElement("a");
            link.setAttribute('download',name);
            link.href = url;
            link.target = '_blank';
            document.body.appendChild(link);
            link.click();
            link.remove();
        },
        getcollaborators(collaborators){
            let colab=''
            collaborators.map(c=>{
                colab=`${colab}${c.email}\n`
            });
            return colab
        },
        loadMoreItems(){
            this.loadMore=true;
            this.filters.page+=1
            this.getDocumnetsList(this.getDocType, this.filters)
        }
    }
    
}
</script>
<style scoped>
.main-text{
    font-size: 18px;
    line-height: 24px;
    font-weight: 500;
    color: #4B465C;
}
.p-2rem{
    padding-bottom: 2rem;
}
</style>